<template>
	<div class="bathOrders">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label" style="display: inline-block;width:75px">关键字: </label>
				<el-input v-model="searchKey" placeholder="商品名称、编码或订单编号" clearable style="width: 250px;"></el-input>
			</div>
			</br>
			<div class="filter-item">
				<label class="label">支付时间: </label>
				<el-date-picker type="datetime" placeholder="开始时间" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="结束时间" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions'></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button style="width:90px;" @click="filterFun">查询</el-button>
				<router-link to="/order/logisticsAssistant/typingRecord" class="lookRecord">查看打单记录>>></router-link>
			</div>
		</div>

		<!-- 表单 -->
		<el-table :data="groupData" @selection-change="handleSelectionChange" @expand-change="expandFunction" :row-key="rowKey"
		 :expand-row-keys="expands" style="width: 100%;" v-loading="loading" ref="multipleTable">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column prop="OrderNo" label="订单编号"></el-table-column>
			<el-table-column prop="ReceiveName" label="收货人">
				<template slot-scope="scope">
					<div>{{scope.row.ReceiveName}}</div>
					<div>{{scope.row.ReceivePhone}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="ReceiveProvince" label="收货地址" width="250">
				<template slot-scope="scope">
					<span>{{scope.row.ReceiveProvince}}</span>
					<span>{{scope.row.ReceiveCity}}</span>
					<span>{{scope.row.ReceiveArea}}</span>
					<div>{{scope.row.ReceiveAddress}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="CustomerRemark" label="买家留言"></el-table-column>
			<el-table-column prop="SystemRemark" label="系统备注"></el-table-column>
			<el-table-column type="expand" label="全部展开" width="120">
				<template slot="header" slot-scope="scope">
					<div @click="isExpandAllfun">
						<span style="margin-right: 5px;" v-if="!isExpandall">全部展开</span>
						<span style="margin-right: 5px;" v-if="isExpandall">全部收起</span>
						<span class="el-icon-arrow-right" v-if="!isExpandall"></span>
						<span class="el-icon-arrow-down" v-if="isExpandall"></span>
					</div>
				</template>
				<!-- 商品 -->
				<template slot-scope="scope">
					<div class="productsClass">
						<el-table :data="scope.row.productData" @expand-change="expandFunction1" :row-class-name="setClassName" :row-key="rowKey1"
						 style="width: 100%;" v-loading="productLoading" ref="productDataTable">
							<el-table-column label="商品" width="400">
								<template slot-scope="scope">
									<div class="product-info" style="align-items:flex-start">
										<img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl+'@!cut64'">
										<svg-icon v-else icon-class="noImgIcon" />
										<div style="min-height: auto;">
											<div style="display:flex;justify-content:space-between;align-items:flex-start;">
												<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.IsMix">[组合]</div>
												<div style="width:300px;">
													<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
												</div>
												<i class="el-icon-edit-outline" @click="productEdit(scope.row)" v-if="scope.row.isShoweite"></i>
											</div>
											<div>{{scope.row.ProductNo}}</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="name" label="规格" width="170">
								<template slot-scope="scope">
									<span v-if="scope.row.SpecValue || scope.row.SpecValue2">
										<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
										<span v-if="scope.row.SpecValue2">,{{scope.row.SpecValue2}}</span>
									</span>
									<span v-else>默认规格</span>
									<div>{{scope.row.BarCode}}</div>
								</template>
							</el-table-column>
							<el-table-column prop="ProductPrice" label="商品价格/件" width="170"></el-table-column>
							<el-table-column prop="WaitSendCount" label="待发货数量" width="170"></el-table-column>
							<el-table-column prop="RealMoney" label="小计(元)" width="170"></el-table-column>
							<!-- 组合明细 -->
							<el-table-column type="expand" width="120">
								<template slot-scope="scope" v-if="scope.row.IsMix">
									<div style="overflow:hidden;" v-loading="mixloading">
										<div class="reply-expand" :key="index" v-for="(item ,index) in scope.row.MixList">
											<div class="product-info">
												<img v-if="item.ImgUrlComplete" :src="item.ImgUrlComplete+'@!cut64'">
												<svg-icon v-else icon-class="noImgIcon" />
												<div style="min-height: auto;">
													<div style="display:flex;justify-content:space-between;align-items:flex-start;">
														<div style="width:280px;">
															<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{item.ProductName}}</pre>
														</div>
													</div>
													<div>{{item.ProductNo}}</div>
												</div>
											</div>
											<div class="product-spec">
												<div style="padding-bottom:10px;">
													<span v-if="item.SpecValue || item.SpecValue2">
														<span v-if="item.SpecValue">{{item.SpecValue}}</span>
														<span v-if="item.SpecValue2">,{{item.SpecValue2}}</span>
													</span>
													<span v-else>默认规格</span>
												</div>
												{{item.Barcode}}
											</div>
											<div class="product-msg">
												<div>{{item.ProductPrice}}</div>
											</div>
											<div class="product-msg">
												<div>{{item.ProductCount}}</div>
											</div>
											<div class="product-msg">
												<div>{{item.RealMoney }}</div>
											</div>
										</div>
									</div>
								</template>

							</el-table-column>
						</el-table>
					</div>
				</template>
			</el-table-column>
		</el-table>

		<div style="margin-top: 20px;">
			<el-checkbox @change="tabCheckedChange" v-model="checked">当前页全选</el-checkbox>
			<el-button size="mini" type="primary" style="margin-left: 20px;" @click="orderBatchs">批量打单</el-button>
		</div>

		<div style="text-align: right;">
			<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="Total">
			</el-pagination>
		</div>

		<!-- 批量打单的弹框 -->
		<el-dialog title="选择物流公司" :visible.sync="SendDialogVisible" width="650px" :before-close="closeDeliver">
			<el-form :model="sendForm" ref="sendForm" :rules="rules">
				<el-form-item label="请选择物流公司 :" label-width="150px" prop="value">
					<!-- <el-select v-model="sendForm.ExpressId" placeholder="请选择" clearable filterable style="width:300px;">
						<el-option v-for="(i,index) in  ExpressList" :key="index" :label="i.DeliveryId+(i.bizid)" :value="i.Id"></el-option>
					</el-select> -->
					<el-cascader v-model="sendForm.value" :props="props" filterable clearable :options="ExpressList" style="width:400px;"></el-cascader>

				</el-form-item>
			</el-form>

			<div style="text-align: center;margin-top: 30px;">
				<el-button @click="closeDeliver" style="width:120px">关 闭</el-button>
				<el-button type="primary" @click="submitSends('sendForm')" :loading="sureLoading" style="width:120px">开始打单</el-button>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		orderIndexinit,
		expressAssistantorder,
		expressAssistantDetail,
		expressAssistantextenmix,
		expressAssistanBatchpush,
		expressAssistanInit
	} from '@/api/goods'
	import config from '@/config/index'
	export default {
		data() {
			return {
				searchKey: '',
				starTime: '',
				endTime: '',
				groupData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				productData: [],
				productLoading: false,
				multipleSelection: [],
				isCurrentAll: false,
				SendDialogVisible: false,
				sendForm: {
					value: []
				},
				rules: {
					value: [{
						required: true,
						message: '请选择物流公司',
						trigger: 'change'
					}],
				},
				ExpressList: [],
				checked: false,
				sureLoading: false,
				isExpandall: false,
				expands: [],
				OrderNoList: [],
				allProducts: [],
				productOrderNo: '',
				productDetailId: '',
				mixloading: false,
				imgUrl: config.IMG_BASE,
				props: {
					label: 'DeliveryName',
					value: 'DeliveryId',
					children: 'children'
				},
			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.starTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			}
		},
		created() {
			this.orderIndexinit()
			this.getList()
		},
		methods: {
			rowKey(row) {
				return row.OrderNo
			},
			rowKey1(row) {
				return row.Id
			},
			setClassName({
				row,
				index
			}) {
				// 通过自己的逻辑返回一个class或者空
				let isShow = false
				if (row && row.IsMix) {
					isShow = row.IsMix
				}
				if (!isShow) {
					return 'unexpand'
				} else {
					return ''
				}
			},
			//取消
			closeDeliver() {
				this.sendForm.value=''
				this.sendForm.ExpressId = ''
				this.SendDialogVisible = false
			},
			isExpandAllfun() {
				this.isExpandall = (!this.isExpandall)
				this.OrderNoList = []
				this.groupData.map(item => {
					this.OrderNoList.push(item.OrderNo)
				})

				if (this.isExpandall) {
					this.expands = this.OrderNoList
				} else {
					this.expands = []
				}
				this.expandList()
			},
			// 展开方法
			expandFunction(row) {
				// this.OrderNoList = []
				this.OrderNoList.push(row.OrderNo)
				this.expandList()
			},
			// 商品展开的方法
			expandFunction1(row) {
				this.productDetailId = ''
				this.productDetailId = row.Id
				this.getMixlist()

			},

			handleSelectionChange(val) {

				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.groupData.length ? true : false
				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			filterFun() {
				this.isExpandall = false
				this.expands = []
				this.OrderNoList = []
				this.currentPage = 1
				this.getList()
			},
			// 列表初始化数据
			async orderIndexinit() {
				try {
					let result = await expressAssistanInit()
					this.ExpressList = result.Result.AccountList;
					this.ExpressList.map(item => {
						item.DeliveryName = item.DeliveryName + '(' + item.BizId + ')'
						item.children = []
						let obj = item.ServiceTypeDic
						for (let i in obj) {
							let objs = {
								DeliveryId: i,
								DeliveryName: obj[i]
							}
							item.children.push(objs)
						}
						return item
					})

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 确认打单
			submitSends(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.sureLoading = true;
						try {
							let OrderNoLists = []
							this.multipleSelection.map(item => {
								OrderNoLists.push(item.OrderNo)
							})

							let BizId = ""
							let DeliveryId = ""
							let ServiceTypeId = ""
							this.ExpressList.map(record => {
								if (record.DeliveryId ==this.sendForm.value[0]) {
									BizId = record.BizId,
									DeliveryId = record.DeliveryId
								}
							})
							if(!OrderNoLists.length){
								this.$message({
									showClose: true,
									type: 'error',
									message: '请选择批量打单的订单!'
								});
								this.sureLoading = false;
								this.sendForm.ExpressId = ''
								this.sendForm.value=''
								this.SendDialogVisible = false
								return 
							}
							let data = {
								OrderNoList: OrderNoLists,
								BizId: BizId,
								DeliveryId: DeliveryId,
								ServiceTypeId: this.sendForm.value[1],
							}
							let result = await expressAssistanBatchpush(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.closeDeliver()
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.expands = []
							this.getList()
							this.sureLoading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			// 当页全选
			tabCheckedChange() {
				this.$refs['multipleTable'].toggleAllSelection();
			},
			// 批量打单
			orderBatchs() {
				if (this.multipleSelection.length < 1) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请选择需要打单的订单!'
					});
					return
				}
				this.SendDialogVisible = true
			},
			// 获取列表数据
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey, // 搜索关键字
						StartTime: this.starTime,
						EndTime: this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await expressAssistantorder(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			// 列表展开
			async expandList() {
				try {
					this.productLoading = true
					let data = {
						OrderNoList: this.OrderNoList
					}
					let result = await expressAssistantDetail(data)
					this.groupData.map(item => {
						item.productData = []
						result.Result.map(record => {
							if (item.OrderNo == record.OrderNo) {
								item.productData = record.DetailList
							}
						})
						return item
					})


				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.productLoading = false
				}

			},

			// 获取组合商品明细
			async getMixlist() {
				try {
					this.mixloading = true
					let data = {
						DetailId: this.productDetailId
					}
					let result = await expressAssistantextenmix(data)
					this.groupData.map(item => {
						item.productData.map(reocrd => {
							reocrd.MixList = []
							if (reocrd.Id == this.productDetailId) {
								reocrd.MixList = result.Result
							}

						})
						return item
					})

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.mixloading = false
				}
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.isExpandall = false
				this.expands = []
				this.OrderNoList = []
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.isExpandall = false
				this.expands = []
				this.OrderNoList = []
				this.getList();
			},

		},


	}
</script>

<style lang="less">
	.bathOrders {
		padding: 10px;
		background: #fff;

		.lookRecord {
			font-size: 14px;
			cursor: pointer;
			color: #409EFF;
			margin-left: 15px;
		}

		.productsClass {
			::v-deep .el-table__expanded-cell {
				padding: 0 !important;
			}
		}

		.reply-expand {
			// z-index: 999;
			width: 100%;
			background-color: #f4f5f7;
			// margin-top:-1px;
			display: flex;
			flex-direction: row;
			padding: 10px;

			.product-info {
				width: 392px;
				// background-color: #409EFF;
			}

			.product-msg {
				word-wrap: break-word;
				width: 170px;
				padding: 0 9px;
				display: flex;
				align-items: center;
			}

			.product-spec {

				word-wrap: break-word;
				width: 170px;
				padding: 0 9px;
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

		}

		::v-deep .unexpand .el-table__expand-column .cell {
			display: none;
		}

		::v-deep .unexpand .el-table__expanded-cell {
			display: none;
		}

		::v-deep .el-table__expanded-cell[class*=cell] {
			padding: 0 0 0 50px;
		}

		::v-deep .el-table__expanded-cell {
			padding-left: 50px;
		}


	}
</style>
